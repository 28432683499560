import React from "react";
import { graphql } from "gatsby";

import Layout from "../../components/Layout";
import MetaComponents from "../../components/seo/MetaComponents";
import GreyHeader from "../../components/GreyHeader";
import Article from "../../components/Article";
import BreadcrumbList from "../../components/seo/BreadcrumbList";
import ArticleStructuredData from "../../components/seo/ArticleStructuredData";
import TipsCarousel from "../../components/tipsAndTricks/TipsCarousel";
import { TIPS_AND_TRICKS_LIST } from "../../constants/tipsAndTricksList";

const Index = () => {
    const key = "zinsModelle";

    const { question, answer } = TIPS_AND_TRICKS_LIST[key];

    return (
        <Layout>
            <MetaComponents title={`${question} | miracl`} description={answer} />

            <GreyHeader title={question} />

            <Article hasMarginTop hasColumns>
                <h3>Fixzins</h3>

                <p>
                    Wie der Name schon sagt, bleibt das Zinsniveau bei einem Fixzinskredit über einen vertraglich
                    fixierten Zeitraum unverändert. Diese Kreditvariante erlaubt es dir daher besser zu planen und deine
                    Ausgaben langfristig zu berechnen.
                </p>
                <p>
                    Da Sondertilgungen (das sind zusätzliche Rückzahlungen, die über deine vereinbarte Kreditrate
                    hinausgehen) bei Krediten mit diesem Zinsmodell in der Regel mit einer Pönale von 1 % belegt werden,
                    kannst du einen fix verzinsten Kredit mit weniger Eigenkapital finanzieren.
                </p>

                <p>
                    Die aktuelle Wirtschaftslage hat bereits große Auswirkungen auf den Fixzins. Dieser hat sich
                    innerhalb eines halben Jahres (Jänner 2022 – Juni 2022) von 1,27% auf 2,8% mehr als verdoppelt.
                    Beispielhaft bedeutet das für eine:n Käufer:in mit Eigenkapital von 70.000 € und einem monatlichen
                    Einkommen von 2.500 €, dass man sich Anfang des Jahres noch leicht 50 qm Wohnraum hätte leisten
                    können, mittlerweile nur mehr 36 qm.
                </p>

                <p>
                    <br />
                    <h3>Variabler Zins</h3>
                    Kredite mit variablen Zinsen immer günstiger als Fixzinskredite. Dennoch solltest du einen Kredit
                    mit diesem Zinsmodell nur dann wählen, wenn du über freie Vermögenswerte, in der Höhe von zumindest
                    einem Drittel des Kreditvolumens verfügst.
                </p>

                <p>
                    Das ist ratsam, um in Zukunft Sondertilgungen tätigen zu können, die die Kreditlaufzeit verkürzen.
                    Auch eine möglichst günstige Entwicklung deines Einkommens ist für die Wahl eines Kredits mit
                    variabler Verzinsung essenziell: Ist doch mit zukünftigen Sollzinssätzen von bis zu 6 % zu
                    kalkulieren.
                </p>
            </Article>

            <TipsCarousel ignoreId={key} title={"Andere Tips"} />

            <BreadcrumbList page={key}></BreadcrumbList>

            <ArticleStructuredData page={key} heading={question} description={answer} datePublished="2022-09-12" dateModified="2022-09-12" />
        </Layout>
    );
};

export default Index;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { ns: { in: ["header", "footer"] }, language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
